@keyframes shine {
	0% {
		transform: translateX(-1000px) rotate(-24.22deg)
	}
	100% {
		transform: translateX(1200px) rotate(-24.22deg)
	}
}

.shine {
	transform: translateX(-1000px) rotate(-24.22deg);
	animation: shine 2.5s linear 2s infinite alternate;
}
