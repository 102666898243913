.coin {
	background: linear-gradient(
		135deg,
		#bdbdbd 14.55%,
		#bdbdbd 25.19%,
		#ffffff 34.41%,
		#e3e3e3 43.62%,
		#aaaaaa 53.55%,
		#f6f6f6 62.77%,
		#ffffff 71.28%,
		#d0d0d0 78.37%,
		#ffffff 85.46%
	);
}

.coin1 {
	background: linear-gradient(
		135deg,
		#ffffff 14.54%,
		#d0d0d0 21.63%,
		#ffffff 28.73%,
		#f6f6f6 37.24%,
		#aaaaaa 46.46%,
		#e3e3e3 56.39%,
		#ffffff 65.61%,
		#bdbdbd 74.83%,
		#bdbdbd 85.47%
	);
}

.coin2 {
	background: conic-gradient(
		from 135deg at 50.02% 50%,
		#ffffff -30.43deg,
		#d0d0d0 8.47deg,
		#bdbdbd 43.47deg,
		#f6f6f6 72.43deg,
		#ffffff 146.59deg,
		#bbbaba 178.49deg,
		#e3e3e3 212.4deg,
		#ffffff 261.8deg,
		#bdbdbd 295.22deg,
		#ffffff 329.57deg,
		#d0d0d0 368.47deg
	);
}

.coin3 {
	background: #d2d2d2;
	mix-blend-mode: multiply;
	border: 0.953854px solid #bbbbbb;
	box-sizing: border-box;
}
